import styled from '@emotion/styled';
import { TypeBlogPostSkeleton } from '../../../../../@types/generated';
import { breakpoints, spacing } from '../../../../utils/styleguide';
import SectionTitle from '../../SectionTitle';
import Carousel from '../../carousel/Carousel';
import Container from '../Container';
import ArticleCarouselCard from './ArticleCarouselCard';
import { EntryCollection } from 'contentful';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${spacing[2]}px;
  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    padding: 0 ${spacing[5]}px;
  }
`;

export default function ArticleCarouselLayout({
  sectionTitle,
  articles,
  backgroundColor,
}: {
  sectionTitle?: string;
  articles?: EntryCollection<TypeBlogPostSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;
  backgroundColor?: string;
}) {
  if (!articles?.items.length) return null;

  return (
    <Container backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <ContentWrapper>
        {!!articles?.items.length && (
          <Carousel differentNrOfSlidesPerBreakpoint maxNrOfSlides={3}>
            {articles?.items.map((article, index) => (
              <ArticleCarouselCard key={index} article={article} />
            ))}
          </Carousel>
        )}
      </ContentWrapper>
    </Container>
  );
}
